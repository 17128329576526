<template>
  <div class="container mescroll-touch">
    <img class="poster" src="@/assets/images/poster.jpg" />
    <div class="activityRules" @click="ElectronicPhotoAgree = true"></div>
    <div class="userPointsBox">
      <span class="userName">{{UserInfo.NickName}}</span>
      <span class="userPoints">当前累计积分：{{Integral}}</span>
    </div>
    <span class="task1">（{{IsLike ? 1 : 0}}/1）</span>
    <span class="task2">（{{IsPoster ? 1 : 0}}/1）</span>
    <div class="videoBtn" @click="toVideoUpload"></div>
    <div class="imageBtn" @click="toElectronicPhoto"></div>

    <van-overlay :show="ElectronicPhotoAgree" :lock-scroll="false">
      <div class="wrapper" @click.stop>
        <div class="InformedConsent">
          <p class="title">活动规则</p>
          <!-- <van-icon class="close" name="close" @click="ElectronicPhotoAgree = false" /> -->
          <div class="content">
          <p>总则：<br/>本平台旨在为平台用户创建高效的沟通渠道，连接用户，支持业务，孵化创新。</p><br/>
          <p>内容发布规则：</p>
          <ul class="ul">
            <li>您可使用本平台发表属于您原创或您有权发表的观点看法、文字、信息、图片、视频等内容。您在本平台发布的任何内容、信息均不代表公司的观点、立场或政策，除法律法规明确规定外，您需独立承担所发布内容的责任<span>（所上传的视频及照片中请勿包含除本人以外的其他个人（包括但不限于患者、医生、医护、非临人员和政府官员）的个人信息（姓名、联系方式、工作单位、职业、面部影像等）</span></li>
            <li>您通过平台发布内容时必须遵守有关法律法规及强生公司相关政策，不得利用平台制作、复制、发布、传播、存储任何违反法律法规、危害国家安全、可能对用户财产安全或信息安全造成损失的信息、营销信息、低俗不当信息、不良信息以及不实信息等内容。</li>
            <li>用户在本平台内的所有言论均不得违反国家的法律法规和强生公司相关政策。根据《互联网新闻信息服务管理规定》和强生公司相关政策，用户的言论不得含有下列内容：
              <p>（一）违反中华人民共和国宪法确定的基本原则</p>
              <p>（二）危害国家安全，泄露国家秘密，颠覆国家政权、破坏国家统一</p>
              <p>（三）损害国家荣誉和利益</p>
              <p>（四）煽动民族仇恨，民族歧视，破坏民族团结</p>
              <p>（五）破坏国家宗教政策，宣扬邪教和封建迷信</p>
              <p>（六）散布谣言，扰乱社会秩序，破坏社会稳定</p>
              <p>（七）散布淫秽，色情，赌博，暴力，恐怖或者教唆犯罪</p>
              <p>（八）侮辱或者诽谤他人，侵害他人合法权益</p>
              <p>（九）煽动非法集会，结社，游行，示威，聚众扰乱社会秩序</p>
              <p>（十）以非法民间组织名义活动</p>
              <p>（十一）含有法律、法规、规章、地方规范性文件、国家政策、政府通知、公序良俗等禁止的内容</p>
              <p>（十二）违反公司合规政策</p>
              <p>（十三）涉及公司商业机密的内容</p>
              <p><span class="highlight">（十四）所上传的视频及照片中请勿包含除本人以外的其他个人（包括但不限于患者、医生、医护、非临人员和政府官员）的个人信息（姓名、联系方式、工作单位、职业、面部影像等）</span></p>
              <p>（十五）本平台认为不利于平台生态，可能给平台造成损失的内容（对于有界定争议的内容，平台运营管理委员会享有最终决断权）</p>
            </li>
          </ul><br/>
          <p>平台运营管理委员会：</p>
          <ul class="ul">
            <li>用户在平台发表违规言论发布违规内容的，平台运营管理委员会成员（缝线HCC champion，平台运营管理人员）有权删除其全部或部分内容，公司有权依据公司政策视情节严重程度给予处罚</li>
          </ul><br/>
          <p>内容分享与使用：</p>
          <ul class="ul">
            <li>本平台中所发布的信息内容仅限在公司内部有访问权限的人员之间进行分享。未经平台允许不可复制、读取、采用、统计平台的信息内容及相关数据，或进行任何形式的销售和商业使用，或者向第三方泄露、提供或允许第三方为任何形式的使用。</li>
          </ul><br/>
          <div class="agree" @click="agree">同意</div>
          </div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import storage from "../../common/utils/storage";
export default {
  data() {
    return {
      UserInfo: null,
      ElectronicPhotoAgree: false,
      Integral: 0,
      IsLike: false,
      IsPoster: false
    }
  },
  created() {
    this.$store.commit('changeNavbarIndex', 0);
    this.UserInfo = sessionStorage.getItem("UserInfo") ? JSON.parse(sessionStorage.getItem("UserInfo")) : null;

    let ElectronicPhotoAgree = storage.getStore('ElectronicPhotoAgree');
    if(!ElectronicPhotoAgree) {
      this.ElectronicPhotoAgree = true;
    }

    this.getIntegral();
  },
  methods: {
    toVideoUpload() {
      this.$router.push('videoUpload');
    },
    toElectronicPhoto() {
      this.$router.push('electronicPhoto');
    },
    getIntegral() {
      this.$axios.get('/Api/Api/Web/Article/Calculation?userid=' + this.UserInfo.Id).then(res => {
        let data = res.Data;
        this.IsLike = data.IsLike;
        this.IsPoster = data.IsPoster;
        if(data.IsLike == true && data.IsPoster == true) {
          this.Integral = 30;
        } else if(data.IsLike == true) {
          this.Integral = 20;
        } else if(data.IsPoster == true) {
          this.Integral = 10;
        }
      })
    },
    agree() {
      storage.setStore('ElectronicPhotoAgree', 'true');
      this.ElectronicPhotoAgree = false;
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  position: relative;
  padding-bottom: 50px;
  .poster {
    display: block;
    width: 100%;
  }
  .activityRules {
    position: absolute;
    top: 8.1%;
    right: 0;
    width: 19%;
    height: 32px;
  }
  .userPointsBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    top: 44.3%;
    left: 7%;
    width: 86%;
    color: #FFFFFF;
    .userName {
      font-size: 15px;
    }
    .userPoints {
      font-size: 12px;
    }
  }
  .task1 {
    position: absolute;
    top: 49.3%;
    left: 14%;
    font-size: 15px;
    color: #FFFFFF;
  }
  .task2 {
    position: absolute;
    top: 55.8%;
    left: 18.5%;
    font-size: 15px;
    color: #FFFFFF;
  }
  .videoBtn {
    position: absolute;
    top: 48.7%;
    right: 6.5%;
    width: 20%;
    height: 32px;
    border-radius: 32px;
  }
  .imageBtn {
    position: absolute;
    top: 55.2%;
    right: 6.5%;
    width: 20%;
    height: 32px;
    border-radius: 32px;
  }
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.InformedConsent {
  position: relative;
  width: 85%;
  height: 80%;
  background-color: #fff;
  border-radius: 6px;
  font-size: 12px;
  padding: 12px 0;
  box-sizing: border-box;
  .title {
    font-size: 15px;
    text-align: center;
    font-weight: bold;
  }
  .close {
    position: absolute;
    top: 12px;
    right: 12px;
    font-size: 18px;
  }
  .content {
    height: 95%;
    overflow: auto;
    padding: 0 12px;
  }
  p {
    line-height: 1.8;
  }
  .ul {
    list-style-type: disc;
    margin-left: 30px;
    li {
      line-height: 1.8;
    }
  }
  .child_ul {
    list-style-type: circle;
    margin-left: 14px;
    li {
      line-height: 1.8;
    }
  }
  .highlight {
    font-weight: bold;
    background-color: yellow;
  }
  .agree {
    width: 80px;
    line-height: 34px;
    border-radius: 4px;
    background-color: #CA001B;
    color: #FFFFFF;
    font-size: 14px;
    text-align: center;
    margin: 0 auto 10px;
  }
}
.van-overlay {
  z-index: 999;
}
</style>